import React, { useEffect, useState } from 'react';
import CardCover from '@components/CardV3/CardCover';
import { getHoursCard, handleOnEnterKeyPress, isLivestreamCourse, isVirtualClassroom } from '../../utility/Api';
import { LangMap, CoursesMap, LevelEnrichedMap } from '../../model/CoursesClass';
import { useSelector } from '@hooks/redux';
import { getAssociatedSessionFromCourse } from '@utility/upcomingEventsUtility';
import { UserProfile } from '@model/User';
import clsx from '@utility/clsx';
import { CardDisplayProps } from './CardDisplayUtils';
import { createIcs } from '@utility/CalendarUtility';
import { getLevelLabel } from '@utility/levelUtility';
import ButtonV2 from '@components/UI/ButtonV2';
import LazySVG from '@components/LazySvg';
import RibbonCourseEvolution from '@components/UI/RibbonCourseEvolution';
import { purifyItemDataElementIdandApplyCamelCasingBrandCase } from '@utility/analytics-utils';
import { buildClickDataTrackingObject } from '@model/TrackingClass';
import { isVCLiveNow } from '@utility/VCUtility';

const CardUpcomingEvents = ({
  course,
  openCourseModal,
  badgeLabel,
  badgeColor,
  badgePriority,
  typeHarvard,
}: CardDisplayProps) => {
  const lang: LangMap = useSelector(state => state.utils.lang);
  const userProfile: UserProfile = useSelector(state => state.user.userProfile);
  const coursesMap: CoursesMap = useSelector(state => state.course.coursesMap);
  const labelComplexityMap = useSelector(state => state.utils.labelComplexityMap);
  const labelL1Map: LevelEnrichedMap = useSelector(state => state.utils.labelL1Map);

  const [teacherLabel, setTeacherLabel] = useState<string>('');
  const [timeLabel, setTimeLabel] = useState<{ timeStart: string; timeFinish: string }>(null);

  // console.log(timeLabel)

  const handleClickCard = () => {
    openCourseModal();
  };
  const handleKeyDown = event => {
    handleOnEnterKeyPress(event, handleClickCard, true);
  };

  let dataElementId = '';
  const contentType = purifyItemDataElementIdandApplyCamelCasingBrandCase(getLevelLabel(course?.complexityId, labelComplexityMap));

  if (course?.isPastEvent){
    dataElementId = `liveSession_pastSession_${contentType}_${course?.courseId}`;
  } else {
    dataElementId = `liveSession_upcoming_${contentType}_${course?.courseId}`;
  }

  useEffect(() => {
    let teacherLabelTemp = '';

    if (course) {
      let associatedSession = course.associatedSession;
      if (!associatedSession) {
        //if no session associated --> get first session
        associatedSession = getAssociatedSessionFromCourse(course, userProfile);
      }

      //print teacher name and lastname
      if (associatedSession.trainerFirstname) {
        teacherLabelTemp += associatedSession.trainerFirstname;
      }
      if (associatedSession.trainerFirstname && associatedSession.trainerLastname) {
        teacherLabelTemp += ' ';
      }
      if (associatedSession.trainerLastname) {
        teacherLabelTemp += associatedSession.trainerLastname;
      }

      //print location
      if (teacherLabelTemp && associatedSession?.location) {
        teacherLabelTemp += ' - ';
      }
      if (associatedSession.location) {
        teacherLabelTemp += associatedSession.location;
      }

      //print time start and end
      if (
        course.associatedSession &&
        (course.associatedSession.timeStart || course.associatedSession.timeFinish)
      ) {
        const timeLabelTemp = {
          timeStart: course.associatedSession.timeStart,
          timeFinish: course.associatedSession.timeFinish,
        };
        setTimeLabel(timeLabelTemp);
      }
    }

    setTeacherLabel(teacherLabelTemp);
  }, [course]);

  return (
    <div className={'course-session'} data-id={course.courseIdMaster}>
      <div
        className={clsx('course-session__content', {
          'course-session--passed':
            course.associatedSession?.isSessionPassed && !course.isPastEvent,
        })}
      >
        <div className="course-session__cover-column">
          <a
            className="course-session__cover"
            onClick={handleClickCard}
            tabIndex={0}
            onKeyDown={handleKeyDown}
            role={'region'}
            aria-label={lang?.SHOW_OVERVIEW_ITEM?.replace('{name}', course.courseFullName)}
            data-element-id={dataElementId}
            data-description={dataElementId}
          >
            <CardCover
              image={course.courseOverviewFile}
              courseFullName={course.courseFullName}
              variant="card-upcoming-events"
              hideShadow
              typeHarvard={typeHarvard}
            />
          </a>
          <div className="course-session__status-info">
            <RibbonCourseEvolution
              course={course}
              associatedSession={course?.associatedSession}
              useBadgeLabelLive={true}
              ribbonInfo={{ label: badgeLabel, color: badgeColor, priority: badgePriority }}
            />
            <span className="time-label">{timeLabel && getHoursCard([timeLabel], true)}</span>
          </div>
        </div>
        <section className={`course-session__info `} aria-label={course.courseFullName}>
          <div>
            <div className="course-session__ctype">
              {getLevelLabel(course.complexityId, labelComplexityMap)}
            </div>
            {teacherLabel &&
            <div className="course-session__session-teacher">{teacherLabel}</div>
            }
            <a className="course-session__title" onClick={handleClickCard} data-element-id={dataElementId} data-description={dataElementId}>
              {course.courseFullName}
            </a>
            {((course.associatedSession?.isBooked &&
              !isVCLiveNow(course, userProfile) &&
              !course.associatedSession?.showLiveBadge &&
              !course.associatedSession?.showLiveCountdown) ||
              isLivestreamCourse(course)) &&
              !course.isPastEvent && (
                <ButtonV2
                  variant="text-btn-no-arrow"
                  className="add-to-calendar-button"
                  small
                  handleClick={() =>
                  {buildClickDataTrackingObject("AddToCalendar")
                    createIcs(
                      course.courseIdMaster,
                      course.associatedSession?.id,
                      coursesMap,
                      userProfile?.timezone,
                      lang
                    )
                  }}
                  data-element-id={`liveSessions_${contentType}_AddToCalendar_${course?.courseId}`}
                  data-description={`liveSessions_${contentType}_AddToCalendar_${course?.courseId}`}
                >
                  <LazySVG
                    src="/images/svg-icons/calendar-3.svg"
                    className="calendar-icon"
                    alt={lang.CARD_UPCOMING_SESSION_CALENDAR}
                  />
                  {lang.CARD_UPCOMING_SESSION_CALENDAR}
                </ButtonV2>
              )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default CardUpcomingEvents;
