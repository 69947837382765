import { OnboardingLabelsIconClass } from '@hooks/onboarding/useGetOnboardingLabels';
import {
  Course,
  CourseSession,
  CoursesMap,
  LangMap,
  LevelEnrichedMap,
  LiveStreamInfo,
} from '@model/CoursesClass';
import { UserProfile } from '@model/User';
import {
  isCompletedCourse,
  isCourseCertificateCtype,
  isLearningPath,
  isStartedCourse,
  printDate,
} from './Api';
import { getLevelLabel } from './levelUtility';
import { canShowCelebrationForLocalStorage } from './localStorage';
import { AKAMAI_IMAGE_LEONARDO_URL, courseStatus } from './const';
import { canCourseBeAccessed } from './ecommerceUtility';
import { addIsNotYetAvailableAttribute } from './LPUtility';
import { setLPInSavingProgress } from "@redux-actions/";
import { useDispatch } from 'react-redux';

export const isCourseOnBoarding = (
  course: Course,
  toView: boolean = false,
  isRetail: boolean = false
): boolean => {
  let isOnboarding = !!course?.onboarding ?? false;
  isOnboarding = toView ? isOnboarding && (!!course?.onboardingToView ?? false ) : isOnboarding;
  isOnboarding = isRetail ? isOnboarding && isCourseOnBoardingRetail(course) : isOnboarding;

  return isOnboarding;
};

export const isCourseOnBoardingRetail = (course: Course): boolean => {
  return isLearningPath(course) && course.complexity?.complexity === 'C6';
};

export const isCourseOnBoardingWholesale = (course: Course): boolean => {
  const complexities = ['C10', 'C13'];
  return isLearningPath(course) && complexities.includes(course.complexity?.complexity);
};

export const isDueDateHiddenOnCourse = (course: Course): boolean => {
  const complexities = ['C6', 'C7', 'C8', 'C10', 'C13'];
  return isLearningPath(course) && complexities.includes(course.complexity?.complexity);
};

export const getIconsOnBoarding = (
  lang: LangMap,
  course: Course,
  iconGradient: boolean = false,
  isOnboardingOverview: boolean = false,
  showElearningIconOnlyMoreIcons: boolean = false
) => {
  const icons: OnboardingLabelsIconClass[] = [];

  if (!isCourseOnBoarding(course)) {
    return icons;
  }

  //on the job training
  if (!!course?.onboardingOnTheJobTraining ?? false) {
    icons.push({
      icon:
        '/images/svg-icons/onboarding/' +
        (iconGradient ? 'job-training-gradient' : 'job-training') +
        '.svg',
      label: isOnboardingOverview
        ? lang.ONBOARDINGCOURSE_OVERVIEW_ON_THE_JOB_TRAINING
        : lang.ONBOARDINGCOURSE_ON_THE_JOB_TRAINING,
      descr: lang.ONBOARDINGCOURSE_OVERVIEW_ON_THE_JOB_TRAINING_DESC,
    });
  }

  if (!!course?.onboardingVirtualClassRoom ?? false) {
    //virtual classroom
    icons.push({
      icon:
        '/images/svg-icons/onboarding/' + (iconGradient ? 'vc-user-gradient' : 'vc-user') + '.svg',
      label: isOnboardingOverview
        ? lang.ONBOARDINGCOURSE_OVERVIEW_VC
        : lang.ONBOARDINGCOURSE_VIRTUAL_CLASSROOM,
      descr: lang.ONBOARDINGCOURSE_OVERVIEW_VC_DESC,
    });
  }
  if (!!isOnboardingOverview && (course?.onboardingOneToOne ?? false)) {
    //one to one (face to face)
    icons.push({
      icon:
        '/images/svg-icons/onboarding/' +
        (iconGradient ? 'one-to-one-gradient' : 'one-to-one') +
        '.svg',
      label: lang.ONBOARDING_ONETOONE_ICON_TITLE,
      descr: lang.ONBOARDING_ONETOONE_ICON_DESCR,
    });
  }
  if (!!isOnboardingOverview && (course?.onboardingAssignment ?? false)) {
    //assignment (label homework)
    icons.push({
      icon:
        '/images/svg-icons/onboarding/' +
        (iconGradient ? 'assignment-gradient' : 'assignment') +
        '.svg',
      label: lang.ONBOARDING_ASSIGNMENT_ICON_TITLE,
      descr: lang.ONBOARDING_ASSIGNMENT_ICON_DESCR,
    });
  }

  //online fruition or online training
  //add it if not program expert || at least one other icon is present
  if (!isProgramExpert(course) || icons.length > 0 || !showElearningIconOnlyMoreIcons) {
    icons.unshift({
      icon:
        '/images/svg-icons/onboarding/' + (iconGradient ? 'monitor-gradient' : 'monitor') + '.svg',
      label: isOnboardingOverview
        ? lang.ONBOARDINGCOURSE_OVERVIEW_ONLINE_TRAINING
        : lang.ONBOARDINGCOURSE_ONLINE_TRAINING,
      descr: lang.ONBOARDINGCOURSE_OVERVIEW_ONLINE_TRAINING_DESC,
    });
  }

  return icons;
};

export const getOnboardingCourseFromCoursesMap = (
  coursesMap: CoursesMap,
  toView: boolean = true,
  isRetail: boolean = false
): Course => {
  let onboardingCourse: Course = null;

  if (coursesMap) {
    for (let course of Object.values(coursesMap)) {
      if (isCourseOnBoarding(course, toView, isRetail)) {
        onboardingCourse = course;
        break;
      }
    }
  }

  return onboardingCourse;
};

export class ValuesChart {
  completed: number = 0;
  completedPercentage: number = 0;
  inProgress: number = 0;
  inProgressPercentage: number = 0;
  todo: number = 0;
  booked: number = 0;
  bookedPercentage: number = 0;
  total: number = 0;
}

export const getOnboardingStatistics = (
  course: Course,
  variant: 'total' | 'lessons' | 'vc' | 'assessment'
): ValuesChart => {
  let values = new ValuesChart();

  if(variant === "assessment") {
    values.total = 100;
    if (course?.userCourseStatus === courseStatus.AVAILABLE) {
      values.completedPercentage = 0;
    } else if (course?.userCourseStatus === courseStatus.COMPLETED) {
      values.completedPercentage = 100;
    } else {
      values.completedPercentage = 50;
    }

    return values;
  }

  if(course?.certificateChartInfo) {
    const chartInfos = course.certificateChartInfo;
    values.total = chartInfos?.totalCourses;
    values.completed = chartInfos?.doneCourses;

    values.completedPercentage = (values.completed * 100) / values.total;
  }

  if (course?.onboardingChartsInfo) {
    const onboardingChartsInfoVariant = course.onboardingChartsInfo[variant];

    if (onboardingChartsInfoVariant) {
      values.total = onboardingChartsInfoVariant.totalCourses;
      values.inProgress = onboardingChartsInfoVariant.inProgressCourses;
      values.booked = onboardingChartsInfoVariant.bookedCourses;
      values.completed = onboardingChartsInfoVariant.doneCourses;

      if (values.total > 0) {
        //todo are the remaining courses
        values.todo = values.total - values.completed;
        if (variant === 'vc') {
          values.todo -= values.booked;
        } else {
          values.todo -= values.inProgress;
        }
        //calculate percentages
        values.completedPercentage = (values.completed * 100) / values.total;
        values.inProgressPercentage = (values.inProgress * 100) / values.total;
        values.bookedPercentage = (values.booked * 100) / values.total;
      }
    }
  }

  return values;
};

export class TopicOnboarding {
  id: string;
  label: string;
  description?: string;
  image?: string;
  topics: TopicOnboarding[];
  courses?: Course[];
  numLessonCompleted?: number;
  numLessonTotal?: number;
  playIconValue?: number;
  ctaLP?: Course;
  assignments?: Course[];
}

export const getOnboardingLessonsByTopics = (
  course: Course,
  labelOnboardingTopicsMap: LevelEnrichedMap,
  coursesMap: CoursesMap
): TopicOnboarding[] => {
  if (!course?.learningPathInfoTopic3 || !coursesMap) {
    return [];
  }

  const topicsLevel1: TopicOnboarding[] = [];

  course.learningPath
    .sort((a, b) => a.sequence - b.sequence)
    .forEach(lessonLp => {
      //get lesson
      let lesson: Course = coursesMap[lessonLp.parentId];
      if (!lesson) {
        return;
      }

      //add topic level 1
      if (!lessonLp.topicKey1) {
        return;
      }
      if (
        topicsLevel1.length === 0 ||
        topicsLevel1[topicsLevel1.length - 1]?.id !== lessonLp.topicKey1
      ) {
        topicsLevel1.push({
          id: lessonLp.topicKey1 || '',
          label: getLevelLabel(lessonLp.topicKey1, labelOnboardingTopicsMap),
          description: '',
          topics: [],
        });
      }

      //add topic level 2
      const topicLevel1 = topicsLevel1[topicsLevel1.length - 1];
      if (!lessonLp.topicKey2) {
        return;
      }
      if (
        topicLevel1.topics.length === 0 ||
        topicLevel1.topics[topicLevel1.topics.length - 1]?.id !== lessonLp.topicKey2
      ) {
        topicLevel1.topics.push({
          id: lessonLp.topicKey2 || '',
          label: getLevelLabel(lessonLp.topicKey2, labelOnboardingTopicsMap),
          description: labelOnboardingTopicsMap?.[lessonLp.topicKey2]?.description,
          image: AKAMAI_IMAGE_LEONARDO_URL + '/topics2/' + (lessonLp.topicKey2 || '') + '.jpg',
          topics: [],
          assignments: [],
        });
      }

      //add assignment/one to one/support material to topic 2
      const topicLevel2 = topicLevel1.topics[topicLevel1.topics.length - 1];
      // if (isSupportLessonInOnboarding(lesson)) {
      //   lesson = addIsNotYetAvailableAttribute(lesson, course, coursesMap);

      //   if (isAssignmentLesson(lesson) || isOneToOneLesson(lesson) || isSupportMaterialLesson(lesson)) {
      //     //add assignment or one to one or material to topic2
      //     topicLevel2.assignments.push(lesson);
      //   }
      // }

      //add topic level 3
      if (!lessonLp.topicKey3) {
        return;
      }
      if (
        topicLevel2.topics.length === 0 ||
        topicLevel2.topics[topicLevel2.topics.length - 1]?.id !== lessonLp.topicKey3
      ) {
        topicLevel2.topics.push({
          id: lessonLp.topicKey3 || '',
          label: getLevelLabel(lessonLp.topicKey3, labelOnboardingTopicsMap),
          description: labelOnboardingTopicsMap?.[lessonLp.topicKey3]?.description,
          topics: [],
          courses: [],
        });
      }

      // FOLLOWING CODE IS TO CALCULATE INFO IN FE INSTEAD OF BFF
      //add lesson
      // const topic3 = topicLevel2.topics[topicLevel2.topics.length - 1];
      // topic3.courses.push(lesson);

      // //update total lesson number in topic3
      // if (!topic3.numLessonCompleted) {
      //     topic3.numLessonTotal = 0;
      // }
      // topic3.numLessonTotal++;

      // //update completed lesson number in topic3
      // if (!topic3.numLessonCompleted) {
      //     topic3.numLessonCompleted = 0;
      // }
      // if (isCompletedCourse(lesson)) {
      //     topic3.numLessonCompleted++;
      // }
    });
  let counter = 0;
  topicsLevel1.forEach(topic1 => {
    topic1.topics.forEach(topic2 => {
      topic2.topics.forEach(topic3 => {
        const topic3Info = course.learningPathInfoTopic3[counter];
        counter++;

        //get total and completed lesson numbers
        topic3.numLessonTotal = topic3Info?.totalCourses || 0;
        topic3.numLessonCompleted = topic3Info?.doneCourses || 0;

        //get topic3 lessons
        if (topic3Info) {
          let courses = [];
          for (const childCourseId in topic3Info.statusCourses) {
            //get lesson
            const learningPathInfo = course.learningPath.filter(
              a => a.courseId === childCourseId || a.parentId === childCourseId
            )?.[0];
            if (!learningPathInfo) {
              continue;
            }
            const parentId = learningPathInfo.parentId;
            const sequence = learningPathInfo.sequence;

            let lesson: Course = coursesMap[parentId];
            if (!lesson) {
              continue;
            }
            lesson = addIsNotYetAvailableAttribute(lesson, course, coursesMap);

            courses.push({ lesson, sequence });
          }

          topic3.courses = courses
            .filter(a => !isCourseCertificateCtype(a.lesson))
            .sort((a, b) => a.sequence - b.sequence)
            .map(a => a.lesson);
        }

        if (topic3.numLessonCompleted === topic3.numLessonTotal && topic3.numLessonTotal > 0) {
          //if #lesson completed === #lesson total --> topic3 is completed
          topic3.playIconValue = 100;
        } else {
          //search for at least one started lesson
          topic3.courses.forEach(lesson => {
            if (isStartedCourse(lesson) || isCompletedCourse(lesson)) {
              topic3.playIconValue = 50;
            }
          });

          //if no one started lesson --> topic3 is to be started
          if (!topic3.playIconValue) {
            topic3.playIconValue = 0;
          }

          topic3.ctaLP = cloneDeep(course);
          const topic3LessonIds = topic3.courses.map(a => a.courseId);
          topic3.ctaLP.learningPath = topic3.ctaLP.learningPath.filter(a =>
            topic3LessonIds.includes(a.courseId)
          );
        }
      });

      //remove topic3 if empty
      topic2.topics = topic2.topics.filter(a => a.courses?.length > 0);
    });
  });

  // FOLLOWING CODE IS TO CALCULATE INFO IN FE INSTEAD OF BFF
  // topicsLevel1.forEach(topic1 => {
  //     topic1.topics.forEach(topic2 => {
  //         topic2.topics.forEach(topic3 => {
  //             if (topic3.numLessonCompleted === topic3.numLessonTotal) {
  //                 //if #lesson completed === #lesson total --> topic3 is completed
  //                 topic3.playIconValue = 100;
  //             } else {
  //                 //search for at least one started lesson
  //                 topic3.courses.forEach(lesson => {
  //                     if (isStartedCourse(lesson) || isCompletedCourse(lesson)) {
  //                         topic3.playIconValue = 50;
  //                     }
  //                 });

  //                 //if no one started lesson --> topic3 is to be started
  //                 if (!topic3.playIconValue) {
  //                     topic3.playIconValue = 0;
  //                 }

  //                 topic3.ctaLP = cloneDeep(course);
  //                 const topic3LessonIds = topic3.courses.map(a => a.courseId);
  //                 topic3.ctaLP.learningPath = topic3.ctaLP.learningPath.filter(a => topic3LessonIds.includes(a.courseId));
  //             }
  //         });
  //     });
  // });

  // console.log('topicsLevel1', topicsLevel1);
  return topicsLevel1;
};

export const printDateCardOnboarding = (date: string, lang: LangMap): string => {
  if (!date) {
    return '';
  }
  return printDate(date, lang) + ' ' + date.slice(11, 16);
};

export const printTimeFinishSession = (date: string, lang:LangMap) : string => {
  if (!date) {
    return '';
  }
  return ' - ' + date.split(" ")[1].slice(0, 5);
}

export const printSessionDateCardOnboarding = (session: CourseSession, lang: LangMap): string => {
  return printDateCardOnboarding(session?.sessionDates?.[0]?.timeStart, lang) + printTimeFinishSession(session?.sessionDates?.[0]?.timeFinish, lang);
};

export const printLiveDateCardOnboarding = (liveInfo: LiveStreamInfo, lang: LangMap): string => {
  return printDateCardOnboarding(liveInfo?.timeStart, lang);
};

export const isOnboardingCompleted = (course: Course): boolean => {
  //check if course is onboarding
  if (!isCourseOnBoarding(course)) {
    return false;
  }

  //onboarding is completed if
  //userCourseStatus === "completed" OR every NON optional lesson is completed
  return isCompletedCourse(course) || (!!course.completedMandatory ?? false);
};

export const canShowCelebration = (course: Course, username: string): boolean => {
  return (
    isOnboardingCompleted(course) &&
    canShowCelebrationForLocalStorage(course.courseIdMaster, username)
  );
};

export const isAssignmentLesson = (course: Course): boolean => {
  return !!course?.isAssignment ?? false;
};

export const isOneToOneLesson = (course: Course): boolean => {
  return !!course?.isOneToOne ?? false;
};

export const isSupportMaterialLesson = (course: Course): boolean => {
  return !!course?.isSupportMaterial ?? false;
};

export const isSupportLessonInOnboarding = (course: Course): boolean => {
  return (
    isCourseCertificateCtype(course) ||
    isAssignmentLesson(course) ||
    isOneToOneLesson(course) ||
    isSupportMaterialLesson(course)
  );
};

export const isSupportLessonInOnboardingNoCompletion = (course: Course): boolean => {
  return isCourseCertificateCtype(course); //|| isSupportMaterialLesson(course);
};

export const isCourseMaster = (course: Course): boolean => {
  return isLearningPath(course) && course?.complexity?.complexity === 'C8';
};

export const isProgramC15 = (course: Course): boolean => {
  return isLearningPath(course) && course?.complexity?.complexity === 'C15';
};

export const isProgramExpert = (course: Course): boolean => {
  //return true;
  //return isLearningPath(course) && course?.complexity?.complexity === 'C13';
  // console.log('course?.programExpert', course)
  return isLearningPath(course) && (!!course?.programExpert ?? false);
};

export const isLessonInsideMaster = (course: Course): boolean => {
  return course?.parentLP?.filter(a => a.isMaster)?.length > 0;
};

export const isMasterPurchasable = (course: Course, considerSoldOut: boolean = false): boolean => {
  if (!isCourseMaster(course)) {
    return false;
  }

  if (considerSoldOut && isCourseSoldout(course)) {
    return false;
  }

  const now = new Date();
  const startDate = new Date(course.masterAttributes?.mcBuyStartDate);
  const endDate = new Date(course.masterAttributes?.mcBuyEndDate);

  return now >= startDate && now <= endDate;
};

export const getMasterAvailableSeats = (course: Course): number => {
  let maxCounterTemp = undefined;
  if (isCourseMaster(course)) {
    //calculate remaining tokens for the master
    maxCounterTemp = course.masterAttributes?.availableSubscription;
    if (maxCounterTemp < 0) {
      maxCounterTemp = 0;
    }
  }

  return maxCounterTemp;
};

export const isCourseSoldout = (course: Course): boolean => {
  return course?.masterAttributes?.availableSubscription <= 0;
};

export const canMasterShowWaitingListRequest = (
  course: Course,
  considerUserAlreadyInWaitingList: boolean = false,
  userProfile: UserProfile = null
): boolean => {
  // WAITING LIST IS OOO
  return false;
  let canRequest = false;
  if (isCourseMaster(course)) {
    const now = new Date();
    const startDateWaitingList = new Date(course.masterAttributes?.mcBuyEndDate);
    const endDateWaitingList = getMasterWaitingListEnd(course);

    //waiting list starts when the (purchase end date is passed || sold out)
    //waiting list ends 7 days before the master starts
    if (now < endDateWaitingList && (now >= startDateWaitingList || isCourseSoldout(course))) {
      canRequest = true;
    }

    if (considerUserAlreadyInWaitingList && isUserInWaitingListMaster(course, userProfile)) {
      return false;
    }
  }

  return canRequest;
};

export const getMasterWaitingListEnd = (course: Course): Date => {
  if (!course) {
    return null;
  }

  //waiting list ends 7 days before the master starts
  const sevenDays = 1000 * 60 * 60 * 24 * 7;
  const mcStartDate = new Date(course.masterAttributes?.mcStartDate);
  return new Date(mcStartDate.getTime() - sevenDays);
};

export const isUserInWaitingListMaster = (course: Course, userProfile: UserProfile): boolean => {
  //WAITING LIST IS OOO
  return false;
  if (!course || !userProfile) {
    return false;
  }

  return !!userProfile.waitingList?.[course.courseIdMaster];
};

export const canMasterBeAccessed = (course: Course): boolean => {
  const now = new Date();

  return (
    !!course &&
    canCourseBeAccessed(course) &&
    !!course.masterAttributes?.mcConfirmed &&
    now >= new Date(course?.masterAttributes?.mcStartDate) &&
    !isMasterEnded(course)
  );
};

export const isMasterBetweenWaitingListAndStart = (course: Course): boolean => {
  const now = new Date();

  return (
    !!course &&
    now > getMasterWaitingListEnd(course) &&
    now < new Date(course.masterAttributes?.mcStartDate)
  );
};

export const isMasterEnded = (course: Course): boolean => {
  if (!course) {
    return false;
  }

  const now = new Date();
  return now > new Date(course?.masterAttributes?.mcEndDate);
};

export const mockDataMaster = (course, isOnboardingPage = false) => {
  //SHOW ONLY PLAN IN PREMIUM CONTENT PAGE
  // if (isCourseMaster(course)) {
  //     course.complexityId = "C6";
  //     course.complexity.complexity = "C6";
  // }

  // if (course.courseIdMaster === "lp1725" || course.courseIdMaster === "lp757") {
  if (isCourseMaster(course)) {
    // course.courseSummary = "<p>Test per programma con complexity C8 e certificato. (traduzione italiana)</br>Test demo 20/12. </br>(traduzione italiana)</p>";
    // course.mandatory = 0;
    // course.onboardingOnTheJobTraining = true;
    // course.onboardingVirtualClassRoom = true;
    // course.onboardingOneToOne = true;
    // course.onboardingAssignment = true;
    // course.masterAttributes = {};
    // course.masterAttributes.planExcluded = 1;
    // course.masterAttributes.mcMaxSubscriptions = 10;
    // course.masterAttributes.availableSubscription = 3;
    // course.priceable = true;
    // course.price = "500";
    // course.courseStates = ["upcoming"];
    // course.userCourseStatus = "";
    // course.tags.push('hasVideoPreview: true');

    // MOCK TEACHERS
    // if (isOnboardingPage) {
    //     course.masterAttributes.teachers = [
    //         {
    //             "firstName": "SAYED",
    //             "jobTitle": "Job title",
    //             "lastName": "ABDALLA",
    //             "picture": "https://qu-backend.leonardo.essilorluxottica.com/pluginfile.php/582541/user/icon/f3",
    //             "username": "sayeda",
    //             "virtualClassroomIds": [
    //                 "co20595",
    //                 "co20597",
    //             ]
    //         },
    //         {
    //             "firstName": "IRIA",
    //             "jobTitle": "Training Expert",
    //             "lastName": "BLANCO",
    //             "picture": "https://qu-backend.leonardo.essilorluxottica.com/pluginfile.php/619075/user/icon/f3",
    //             "username": "iriab",
    //             "virtualClassroomIds": [
    //                 "co20595",
    //                 "co20597",
    //             ]
    //         }
    //     ];

    // }

    course.masterAttributes.mcStartDate = '2023/01/17 10:00:00';
    course.masterAttributes.mcEndDate = '2022/12/31 21:00:00';
    course.masterAttributes.mcBuyStartDate = '2022/12/26 20:00:00';
    course.masterAttributes.mcBuyEndDate = '2022/12/31 20:00:00';
    //elearnmasterecp1
    // course.buyed = 0;

    //1) BEFORE SELLABITY PERIOD
    // course.masterAttributes.mcBuyStartDate = "2022/12/25 16:00:00";
    // course.masterAttributes.mcBuyEndDate = "2022/12/26 20:00:00";

    //2) SELLABILITY PERIOD
    // course.masterAttributes.mcBuyStartDate = "2022/12/17 10:00:00";
    // course.masterAttributes.mcBuyEndDate = "2022/12/25 20:17:00";

    //3) SELLABILITY PERIOD - ACTIVATED
    // course.buyed = 1;
    // course.masterAttributes.availableSubscription = 0;
    // course.masterAttributes.mcBuyStartDate = "2022/12/17 10:00:00";
    // course.masterAttributes.mcBuyEndDate = "2022/12/25 20:17:00";

    //4) BETWEEN SELLABILITY AND START MASTER - ACTIVATED
    // course.buyed = 1;
    // course.masterAttributes.mcBuyStartDate = "2022/12/17 10:00:00";
    // course.masterAttributes.mcBuyEndDate = "2022/12/17 13:00:00";

    //5) MASTER STARTED AND CONFIRMED - ACTIVATED
    // course.buyed = 1;
    // course.masterAttributes.mcConfirmed = 1;
    // course.masterAttributes.mcStartDate = "2022/12/17 10:00:00";
    // course.masterAttributes.mcEndDate = "2022/12/29 13:00:00";

    //5.1) MASTER STARTED AND NOT CONFIRMED - ACTIVATED
    // course.buyed = 1;
    // course.masterAttributes.mcConfirmed = 0;
    // course.masterAttributes.mcStartDate = "2022/12/17 10:00:00";
    // course.masterAttributes.mcEndDate = "2022/12/29 13:00:00";

    //6) MASTER ENDED - ACTIVATED
    // course.buyed = 1;
    // course.masterAttributes.mcConfirmed = 1;
    // course.masterAttributes.mcStartDate = "2022/12/17 10:00:00";
    // course.masterAttributes.mcEndDate = "2022/12/17 13:00:00";

    //7) MASTER STARTED
    //DECOMMENTARE IL PUNTO 7) RIPORTATO IN FONDO ALLA FUNZIONE
    // course.buyed = 1;
    // course.masterAttributes.mcConfirmed = 1;
    // course.masterAttributes.mcStartDate = "2022/12/17 10:00:00";
    // course.masterAttributes.mcEndDate = "2022/12/29 13:00:00";
    // course.userCourseStatus = "enrolled";
    // if (course.onboardingChartsInfo) {
    //     course.onboardingChartsInfo.total = {
    //         bookedCourses: 0,
    //         doneCourses: 2,
    //         inProgressCourses: 0,
    //         totalCourses: 3,
    //     };
    // }
    // // if (course.learningPathInfoTopic3?.topic3_frames) {
    // //     course.learningPathInfoTopic3.topic3_frames.doneCourses = 1;
    // // }
    // if (course.learningPathInfoTopic3?.topic3_floor_leadership) {
    //     course.learningPathInfoTopic3.topic3_floor_leadership.doneCourses = 1;
    // }

    //8) CERTIFICATE + CHART
    //DECOMMENTARE IL PUNTO 8) RIPORTATO IN FONDO ALLA FUNZIONE
    // course.buyed = 1;
    // course.masterAttributes.mcConfirmed = 1;
    // course.masterAttributes.mcStartDate = "2022/12/17 10:00:00";
    // course.masterAttributes.mcEndDate = "2022/12/29 13:00:00";
    // course.userCourseStatus = "completed";
    // course.certificateLinks = ["https://qu-backend.leonardo.essilorluxottica.com/mod/customcert/view.php?id=16078&downloadown=1"];
    // if (course.onboardingChartsInfo) {
    //     course.onboardingChartsInfo.total = {
    //         bookedCourses: 0,
    //         doneCourses: 3,
    //         inProgressCourses: 0,
    //         totalCourses: 3,
    //     };
    // }
    // if (course.learningPathInfoTopic3) {
    //     for (let topic3 of Object.keys(course.learningPathInfoTopic3)) {
    //         course.learningPathInfoTopic3[topic3].doneCourses = course.learningPathInfoTopic3[topic3].totalCourses;
    //     }
    // }

    //8.1) COMPLETED + ENDED MASTER
    //IN COMBO CON 8)
    // course.masterAttributes.mcEndDate = "2022/12/17 13:00:00";

    //9) SOLD OUT
    // course.masterAttributes.availableSubscription = 0;
    // course.buyed = 0;
    // course.masterAttributes.mcBuyStartDate = "2022/12/10 16:00:00";
    // course.masterAttributes.mcBuyEndDate = "2022/12/23 20:17:00";

    //10) PERIODO SELLABILITY TERMINATO, MASTER NON INIZIATO, NON SOLD OUT
    // course.masterAttributes.availableSubscription = 5;
    // course.buyed = 0;
    // course.masterAttributes.mcBuyStartDate = "2022/12/10 16:00:00";
    // course.masterAttributes.mcBuyEndDate = "2022/12/12 20:17:00";

    //11) OOO - UTENTE IN WAITING LIST
    //*****MODIFICARE userActions.ts*****
    // course.masterAttributes.availableSubscription = 3;
    // course.buyed = 0;
    // course.masterAttributes.mcBuyStartDate = "2022/12/10 16:00:00";
    // course.masterAttributes.mcBuyEndDate = "2022/12/12 20:17:00";

    //12) OOO - PERIODO TRA FINE WAITING LIST E INIZIO MASTER
    // course.masterAttributes.availableSubscription = 3;
    // course.buyed = 0;
    // course.masterAttributes.mcBuyStartDate = "2022/12/10 16:00:00";
    // course.masterAttributes.mcBuyEndDate = "2022/12/12 20:17:00";
    // course.masterAttributes.mcStartDate = "2022/12/22 10:00:00";
    // course.masterAttributes.mcEndDate = "2023/01/30 20:00:00";

    //13) MASTER INIZIATO MA NON ACQUISTATO+ATTIVATO
    // course.masterAttributes.availableSubscription = 3;
    // course.buyed = 0;
    // course.masterAttributes.mcBuyStartDate = "2022/12/10 16:00:00";
    // course.masterAttributes.mcBuyEndDate = "2022/12/12 20:00:00";
    // course.masterAttributes.mcStartDate = "2022/12/12 20:17:00";

    //     course.learningPath.push({
    //         certificate: false,
    //         cid: [],
    //         courseId: "co11613",
    //         mandatory: true,
    //         parentId: "co11612",
    //         rule: "AND",
    //         sequence: 1,
    //         topicKey1: "topic1_welcome",
    //         topicKey2: "topic2_leadership_insight",
    //         topicKey3: "topic3_floor_leadership",
    //     })
    //     course.learningPath.push({
    //         certificate: false,
    //         cid: [],
    //         courseId: "co4050",
    //         mandatory: true,
    //         parentId: "co4048",
    //         rule: "AND",
    //         sequence: 1,
    //         topicKey1: "topic1_welcome",
    //         topicKey2: "topic2_leadership_insight",
    //         topicKey3: "topic3_floor_leadership",
    //     })
    //     course.learningPath.push({
    //         certificate: false,
    //         cid: [],
    //         courseId: "co4530",
    //         mandatory: true,
    //         parentId: "co4530",
    //         rule: "AND",
    //         sequence: 1,
    //         topicKey1: "topic1_welcome",
    //         topicKey2: "topic2_leadership_insight",
    //         topicKey3: "topic3_floor_leadership",
    //     })
    //     if (course.learningPathInfoTopic3?.topic3_floor_leadership?.statusCourses) {
    //         course.learningPathInfoTopic3.topic3_floor_leadership.statusCourses.co11612 = "available";
    //         course.learningPathInfoTopic3.topic3_floor_leadership.statusCourses.co4048 = "available";
    //         course.learningPathInfoTopic3.topic3_floor_leadership.statusCourses.co4530 = "available";
    //     }
  }
  // if (course.courseIdMaster === "co11612") {
  //     course.isAssignment = true;
  // }
  // if (course.courseIdMaster === "co4048") {
  //     course.isOneToOne = true;
  // }
  // if (course.courseIdMaster === "co4530") {
  //     course.isSupportMaterial = true;
  // }

  // if (course.courseIdMaster === "lp462"
  //     || course.courseIdMaster === "co11887"
  //     || course.courseIdMaster === "co11875"
  //     || course.courseIdMaster === "co15700"
  //     || course.courseIdMaster === "co13822"
  //     || course.courseIdMaster === "co12074"
  //     || course.courseIdMaster === "lp785"
  //     || course.courseIdMaster === "lp784"
  //     || course.courseIdMaster === "lp783"
  //     || course.courseIdMaster === "lp782"
  //     || course.courseIdMaster === "lp765"
  //     || course.courseIdMaster === "lp764"
  // ) {
  //     course.masterAttributes.planExcluded = 1;
  // }

  //7) MASTER STARTED
  // if (course.courseIdMaster === "co20599") {
  //     //|| course.courseIdMaster === "co20595"
  //     course.userCourseStatus = "completed";
  // }

  //8) CERTIFICATE + CHART
  // if (course.courseIdMaster === "co11612"
  //     || course.courseIdMaster === "co4048"
  //     || course.courseIdMaster === "co4530"
  //     || course.courseIdMaster === "co20599"
  //     || course.courseIdMaster === "co20595"
  //     || course.courseIdMaster === "co20597"
  //     || course.courseIdMaster === "co20761"
  // ) {
  //     course.userCourseStatus = "completed";
  // }

  return course;
};




	
	
/**
 * Save LP in progress for onboarding page
*/
export const saveLPInProgress = (course: Course)=>{
  // check parentLP of course
  if(course?.parentLP.length > 0){
    const parentLP = course?.parentLP[0];

    const dispatch = useDispatch();
    dispatch(setLPInSavingProgress(parentLP?.courseId))
}

  // foreach LP save in redux this state
}
