import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps , useDispatch} from "react-redux";
import {
  checkCourseState, getDateCard, getHoursCard, canSignUp, canCancelBooking, canJoinSession, isFaceToFace, canSeeCheckMessage, getSessionsForModal,
  isScheduledSession, isUpcomingInCatalog, getSessionStateByCourseSessions, isAttendedSession, goToBrandPage, isCourseToBeRated, getSessionFirstTrainer, joinSession, getUserFromTrainer, handleOnEnterKeyPress, isSessionOver,
  isVirtualClassroom,
  isCompletedCourse,
  canSignUpSession,
} from "@utility/Api";
import { courseStatesConst, queryParams } from "@utility/const";
import { Course, CoursesMap, getTrainerFullName, LangMap, LevelEnrichedMap, Trainer } from '@model/CoursesClass';
import Avatar from '@components/UI/Avatar';
import CircleProgress from '@components/UI/CircleProgress';
import * as actions from "@redux-actions/";
import { useHistory } from "react-router-dom";
import { USER_URLS } from '@components/link-utils';
import { RootState } from 'src/store/store';
import { createIcs } from '@utility/CalendarUtility';
import { UserProfile } from '@model/User';
import { useSelector } from '@hooks/redux';
import ButtonV2 from '@components/UI/ButtonV2';
import { purifyItemDataElementIdandApplyCamelCasingBrandCase } from '@utility/analytics-utils';
import { getLevelLabel } from '@utility/levelUtility';
import { buildClickDataTrackingObject } from '@model/TrackingClass';
import { isVCLiveNow } from '@utility/VCUtility';


type Props = PropsFromRedux & {
  lang: LangMap;
  course: Course;
  openConfirmBookingModal: (courseId: string, sessionId: string, isSessionSignUp: boolean) => void,
  toggleRatingModal: (val: boolean, course: Course) => void,
  isSpeaker: boolean,

}



const SessionActivities = (props: Props) => {
  const { course, lang, openConfirmBookingModal, toggleRatingModal } = props;
  const { UPCOMING, SCHEDULED, MISSED, ATTENDED, IN_PROGRESS } = courseStatesConst;
  const { courseStates } = course;
  const history = useHistory();
  const userProfile: UserProfile = useSelector((state) => state.user.userProfile);
  const coursesMap: CoursesMap = useSelector((state) => state.course.coursesMap);
  const labelL1Map: LevelEnrichedMap = useSelector(state => state.utils.labelL1Map);
  const dispatch = useDispatch();


  const isDisabled = (session) => {
    let today = new Date(),
      sessionDate = new Date(session.minStartDate);

    return (sessionDate < today && !isVirtualClassroom(course) || (checkCourseState(courseStates, SCHEDULED) && !isScheduledSession(session)))
  }

  const [sessions, setSessions] = useState([]);

  useEffect(() => {

    const sessionsToPrint = getSessionsForModal(course, userProfile);
    setSessions(sessionsToPrint)

  }, [course]);

  const handleJoinSession = (e, session) => {
    e.stopPropagation();

    joinSession(course, session, toggleRatingModal);
  }

  const handleDownloadCalendar = (course, sessionId) => {
    createIcs(course.courseIdMaster, sessionId, coursesMap, userProfile.timezone, lang);
  }
  const timeOnTraining = useSelector(state => state.course.timeOnTraining);

  const labelComplexityMap = useSelector(state => state.utils.labelComplexityMap);
  const contentType = purifyItemDataElementIdandApplyCamelCasingBrandCase(getLevelLabel(course?.complexityId, labelComplexityMap));
  
  const printButton = (session) => {
    // SIGN UP
    if ((checkCourseState(courseStates, UPCOMING) || isVirtualClassroom(course) && checkCourseState(courseStates, IN_PROGRESS) || isUpcomingInCatalog(course)) && canSignUp(session, course)) {
      return (
        <ButtonV2 variant="text-btn-no-arrow" small className="session-action-underline" handleClick={() => {buildClickDataTrackingObject("signUp");openConfirmBookingModal(course.courseIdMaster, session.id, true);dispatch(actions.analyticsOpenModal('Sign-up Popin'))}} disabled={isDisabled(session) && !(isVirtualClassroom(course) && checkCourseState(courseStates, IN_PROGRESS))}>{lang.SIGNUP_CTA}</ButtonV2>
      )
    }

    // JOIN
    if (canJoinSession(session) && (course.showJoin || isVCLiveNow(course, userProfile))) {
      return (
        <ButtonV2 variant="text-btn-no-arrow" small className="session-action-underline" handleClick={e =>{buildClickDataTrackingObject("join");handleJoinSession(e, session)}}>{lang.JOIN_LABEL}</ButtonV2>
      )
    }

    // MESSAGE ATTENDED
    if (isAttendedSession(session)) {
      return (
        <span className="session-action-color">{lang.ATTENDED_LABEL}</span>
      )
    }

    // MESSAGE CHECK ATTENDANCE
    if (isScheduledSession(session) && canSeeCheckMessage(session)) {
      return (
        <span>{lang.WAITING_ATTENDANCE_CHECK_LABEL}</span>
      )
    }

    // CANCEL BOOKING     //ADD TO CALENDAR
    if (checkCourseState(courseStates, SCHEDULED) && canCancelBooking(session)) {
      return (
        <>
          <ButtonV2 variant="text-btn" small  className="calendar-button" handleClick={() =>{buildClickDataTrackingObject("addToCalendar"); handleDownloadCalendar(course, session.id)}} disabled={isDisabled(session)}>{lang.CARD_UPCOMING_SESSION_CALENDAR}</ButtonV2>
          <ButtonV2 variant="text-btn-no-arrow" small  className="session-action-underline" handleClick={() => {buildClickDataTrackingObject("cancelBooking");openConfirmBookingModal(course.courseIdMaster, session.id, false);dispatch(actions.analyticsOpenModal('Cancel Booking Popin'))}} disabled={isDisabled(session)}>{lang.CANCEL_BOOKING_LABEL}</ButtonV2>
        </>
      )
    }
  }

  const openTeacherModal = (teacherUsername: string = '') => {
    if (!teacherUsername) {
      return;
    }
    dispatch(actions.setTeacherUsername(teacherUsername));

    //go to PLP filtered by teacher
    history.push(USER_URLS.SUBCATALOGUE.URL);
   // history.go();
  }



  return (
    <>

      {/* start code for speakers */}
      {props.isSpeaker && course?.liveInfo?.[0]?.trainers &&
        <>
          <span className="text-14 sessions-title">{lang.SPEAKERS_LABEL}</span>
          <div className="sessions" id="sessions">
            {
              course?.liveInfo?.[0].trainers.map(trainer => {
                const session = getUserFromTrainer(trainer);
                return (
                  <div className="card-session speaker" key={session.username} data-id={session.username}>
                    <div className="card-session__content speaker flex column align-center">
                      <div className="session-avatar-wrapper focus-outline" onClick={() => openTeacherModal(session?.username)} onKeyDown={(event) => handleOnEnterKeyPress(event, () => openTeacherModal(session?.username))} tabIndex={0}>
                        <CircleProgress firstPercentageValue={0} />
                        <div className="session-avatar">
                          <Avatar
                            user={session}
                            image={session.picture}
                            variant="session"
                          />
                        </div>
                      </div>
                      <div className="session-info">
                        {(session.firstName && session.lastName) &&
                          <div className="session-teacher-name" onClick={() => openTeacherModal(session?.username)}>
                            {session.firstName + " " + session.lastName}
                          </div>
                        }
                        {(session.jobTitle) &&
                          <div className="session-teacher-role">{session.jobTitle}</div>
                        }
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
          {/* end code for speakers */}
        </>
      }

      {sessions && sessions.length > 0 &&
        <>
          <span className="text-14 sessions-title">{lang.CLASSROOM_DATES}</span>
          <div className="sessions" id="sessions">
            {sessions.map((session) => {
              const trainer: Trainer = getSessionFirstTrainer(session);

              return (
                <div className="card-session" key={session.id} data-id={session.id}>
                  <div className="card-session__content flex column items-center">
                    <div className="card-session__seats-left">
                      {!isSessionOver(session) &&
                        session.availablePlaces > 0 ? lang.SESSION_SEATS_LEFT : lang.SESSION_NO_SEATS_LEFT
                      }
                    </div>
                    <div className="session-avatar-wrapper focus-outline" onClick={() => openTeacherModal(trainer?.username)} onKeyDown={(event) => handleOnEnterKeyPress(event, () => openTeacherModal(trainer?.username))} tabIndex={0}>
                      <CircleProgress
                        firstPercentageValue={
                          isSessionOver(session)
                            ? 100
                            : (session.availablePlaces > 0
                              ? 100
                              : 0
                            )
                        }
                      />
                      <div className="session-avatar">
                        <Avatar
                          // hideImage={!trainer?.picture}
                          image={trainer?.picture}
                          fullName={getTrainerFullName(trainer)}
                          variant="session"
                        />
                      </div>
                    </div>
                    <div className="session-info">
                    {trainer &&
                        <div className="session-teacher-name" onClick={() => openTeacherModal(trainer?.username)}>
                          {getTrainerFullName(trainer)}
                        </div>
                      }
                      <div className="session-date">{getDateCard(session.sessionDates, lang)}</div>
                      <div className="session-hour">{getHoursCard(session.sessionDates, true)}</div>

                      <div className="session-info-box">
                      {session.language &&
                        <div className="session-language">{session.language?.toUpperCase()}</div>
                      }

                      {session.language &&
                        <div className="session-interrupt">{"|"}</div>
                      }

                      {(isFaceToFace(course) && (session.city || session.location)) ?
                        <>
                          {session.city &&
                            <div>{session.city}</div>
                          }
                          {session.location &&
                            <div className="session-address">{session.location}</div>
                          }
                        </>
                        : <span>{lang.LIVE_CLASSROOM}</span>
                      }

                      </div>

<div className="session-action">
                        {printButton(session)}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </>
      }
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    lang: state.utils.lang
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openConfirmBookingModal: (courseId, sessionId, isSessionSignUp) => dispatch(actions.openConfirmBookingModal(courseId, sessionId, isSessionSignUp)),
    toggleRatingModal: (val, course) => dispatch(actions.toggleRatingModal(val, course)),
  };
};


const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(SessionActivities); 
