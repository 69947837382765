import React, { useEffect, useState  } from 'react';
import { Route  } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PUBLIC_URLS, USER_URLS } from '@components/link-utils';
import { COOKIE, queryParams } from '@utility/const';
import { isUserEnabledForTrainingPills } from '@utility/TrainingPillsUtility';
import { getCookie } from '@utility/cookie';
import { useSelector } from '@hooks/redux';
import { handleRedirectAfterLogin, handleRedirectLogin } from '@redux-actions/';
import { useHistory } from 'react-router-dom';
import { isEcommerceActive } from '@utility/ecommerceUtility';
import { isUserEl360 } from '@utility/userUtility';
import { selectPrograms } from '../../store/selectors/coursesSelectors';

type PrivateRouteProps = {
  isTpPage: boolean;
  isEcommercePage: boolean;
  isCommercialOfferPage: boolean;
  isPlanPage: boolean;
  component: any;
  computedMatch: any;
  isProfileSendTokenPage: boolean;
};

const PrivateRoute = ({
  isTpPage = false,
  isEcommercePage = false,
  isCommercialOfferPage = false,
  isPlanPage = false,
  component,
  computedMatch,
  isProfileSendTokenPage = false,
}: PrivateRouteProps) => {

  const props = {
    isTpPage: isTpPage,
    isEcommercePage: isEcommercePage,
    isCommercialOfferPage: isCommercialOfferPage,
    isPlanPage: isPlanPage,
    component: component,
    computedMatch: computedMatch,
    isProfileSendTokenPage: isProfileSendTokenPage,
  }


  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
  const [wasAuthenticated, setWasAuthenticated] = useState(false);
  const [cidParam, setCidParam] = useState('');
  const noOneTpPresent = useSelector(
    state =>
      state.trainingPills.trainingPillsHP?.length === 0 &&
      state.trainingPills.isTrainingPillsHPCompleted
  );
  const ecommerceEnable = useSelector(state => state.user.ecommerceEnable);
  const programs = useSelector(selectPrograms);

  const userProfile = useSelector(state => state.user.userProfile);
  const lang = useSelector(state => state.utils.lang);

  const [renderComponent, setRenderComponent] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {

      const pathname = window?.location?.pathname
      const search = window?.location?.search
      
      if (search?.includes(queryParams.CID) || pathname != '/landing') {

       if(search?.includes(queryParams.CID)) {

        setCidParam(pathname + search);
        localStorage.setItem('CIDURL', pathname + search );
       }

        dispatch(handleRedirectAfterLogin());        
      }
      /**
       * By default I have to land to the /landing page
       * BUT if url has ?courseDetail=coId&courseTitle=title 
       * OR if url has /path1/path2/path3?courseDetail=coId&courseTitle=title
       * I have to land to /login
       * OTHERWISE go to /landing
       */

      let mylToken = getCookie(COOKIE.MYL_TOKEN);
      if(pathname !== '/' || (pathname === '/' && search !== '') || mylToken) 
        return goToLogin();

      return goToLanding();
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      setWasAuthenticated(true);
    } else {
      dispatch(handleRedirectAfterLogin());

      if (wasAuthenticated) {
        getCookie(COOKIE.SESSION_FINISHED) ? goToLanding() : goToLogin();
        setWasAuthenticated(false);
      }
    }

    if (isAuthenticated !== renderComponent) {
      setRenderComponent(isAuthenticated);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    //redirect to HP if the user is in a TP page but he is not enabled to TP
    if (isAuthenticated && isTpPage && userProfile) {
      if (!isUserEnabledForTrainingPills(userProfile) || noOneTpPresent) {
        history.push(USER_URLS.HOMEPAGE.URL);
      }
    }
  }, [isAuthenticated, isTpPage, userProfile, noOneTpPresent]);


  useEffect(() => {
    if (isAuthenticated && localStorage.getItem('CIDURL') && userProfile) {
        history.push(localStorage.getItem('CIDURL'));
        if(window?.location?.href?.includes(localStorage?.getItem('CIDURL') )){
          localStorage?.removeItem("CIDURL");
        }
    }
  }, [isAuthenticated, userProfile]);

  useEffect(() => {
    //redirect to TP detail page if the old query param is present
    if (isAuthenticated && window.location.search.includes(queryParams.PILL_MODEL)) {
      const params = new URLSearchParams(window.location.search);
      const tpId = params.get(queryParams.PILL_MODEL);
      //go to TP page
      history.replace(USER_URLS.DETAILS_TP.URL.replace(':tpId', tpId));
      history.go();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    //redirect to HP if the user is in an ecommerce page but he is not enabled to ecommerce
    if (isAuthenticated && isEcommercePage && !ecommerceEnable) {
      //check cookie because ecommerceEnable is not immediately updated
      let ecommerceCookie = getCookie(COOKIE.ECOMMERCE);
      if (!isEcommerceActive(ecommerceCookie)) {
        history.push(USER_URLS.HOMEPAGE.URL);
      }
    }
  }, [isAuthenticated, isEcommercePage, ecommerceEnable]);
  
  useEffect(() => {
    //redirect to HP if the user is EL360, he is in CommercialOfferPage and he does not have any C8 in his catalog
    if(isAuthenticated && isUserEl360(userProfile) && isCommercialOfferPage && !programs.length) {
      history.push(USER_URLS.HOMEPAGE.URL)
    }
   
  }, [isAuthenticated, userProfile, isCommercialOfferPage, programs]);

  useEffect(() => {
    //redirect to HP if the user is EL360 and he is in PlanPage
    if(isAuthenticated && isUserEl360(userProfile) && isPlanPage) {
      history.push(USER_URLS.HOMEPAGE.URL)
    }
   
  }, [isAuthenticated, userProfile, isPlanPage]);

  useEffect(() => {
    //redirect to generic order history if user tries to land on the old ProfileSendToken page
    if(isAuthenticated && isProfileSendTokenPage) {
     history.push(USER_URLS.PROFILE_ORDER_HISTORY.URL)
    }
   
  }, [isAuthenticated]);

  const goToLanding = () => {
    history.push(PUBLIC_URLS.HOMEPAGE.URL);
  };

  const goToLogin = () => {
    if (window?.location?.search?.includes(queryParams.CID)) {
      //if contains CID query param show it in the url in login page
      let search = window?.location?.search;
      history.push(PUBLIC_URLS.LOGIN.URL + search);
    } else {
      history.push(PUBLIC_URLS.LOGIN.URL);
    }
  };

  const { component: ComponentChild, ...rest } = props;
  return renderComponent ? (
    <Route
      {...rest}
      render={props => (
        <ComponentChild {...props} match={computedMatch} lang={lang} userProfile={userProfile} />
      )}
    />
  ) : null;
};

export default PrivateRoute;
